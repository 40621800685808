import styled from 'styled-components';

export const MainContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 40px 20px;
`;

export const Header = styled.header`
  text-align: center;

  h1 {
    font-size: 46px;
    color: #7159EB;
  }

  p {
    font-size: 20px;
    font-weight: bold;
    color: #3d3d4d;
    margin-top: 20px;
  }

  p.edit-text {
    color: #a8a8b3;
  }

  button {
    background: #7159EB;
    color: #fff;
    font-weight: bold;
    border: 0;
    padding: 10px;
    width: 20%;
    border-radius: 5px;
    transition: opacity 0.2s;
    margin: 10px;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ul {
    list-style: none;
    display: flex;
    width: 80%;
    margin: 30px auto;
    flex-direction: column;
  }

  button {
    background: #7159EB;
    color: #fff;
    font-weight: bold;
    border: 0;
    padding: 10px;
    width: 20%;
    border-radius: 5px;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const QuestionCard = styled.div`
  min-height: 200px;
  background: #fff;
  display: flex;
  flex: 1;
  padding: 20px 30px;
  border: 2px solid #fff;
  border-radius: 5px;
  flex-direction: column;

  border-color: ${(props) => {
    const inputError = props.hasError;
    const questionId = props.questionId;
    if (!inputError) {
      return '#fff';
    } else {
      const [questionIdError] = inputError.split('-');
      if (questionIdError === questionId) {
        return '#c53030';
      }
    }
  }};

  & + li {
    margin-top: 20px;
  }

  p {
    font-size: 24px;
    color: #3d3d4d;
  }

  span {
    font-size: 18px;
    color: #a8a8b3;
  }

  span.dependant {
    margin-bottom: 10px;
    font-size: 18px;
    color: #7159EB;
  }

  span.required-text {
    display: inline-flex;
    margin-bottom: 20px;
    font-size: 14px;
    color: #c53030;
  }

  input[type='text'] {
    margin-top: auto;
    margin-bottom: 10px;
    border-width: 0 0 1px;
    border-color: #949494;
    font-size: 18px;
    padding-left: 5px;
    color: #3d3d4d;
    width: 50%;

    &::placeholder {
      color: #a8a8b3;
    }
  }

  .date {
    margin-top: auto;
    margin-bottom: 10px;
    border-width: 0 0 1px;
    font-size: 18px;
    padding-left: 5px;
    color: #3d3d4d;
    width: 50%;
  }

  @media (max-width: 700px) {
    min-height: 120px;

    label svg {
      width: 20px;
    }

    p {
      font-size: 16px;
    }

    span {
      font-size: 12px;
    }

    span.required-text {
      font-size: 12px;
    }

    input {
      font-size: 10px;

      &::placeholder {
        font-size: 10px;
      }
    }

    input[type='text'] {
      width: 70%;
    }

    .date {
      width: 70%;
    }
  }

  @media (max-width: 375px) {
    label svg {
      width: 15px;
    }

    p {
      font-size: 12px;
    }

    span {
      font-size: 10px;
    }

    span.required-text {
      font-size: 10px;
    }

    input {
      font-size: 8px;

      &::placeholder {
        font-size: 8px;
      }
    }
  }
`;

export const Error = styled.span`
  display: block;
  color: #c53030;
  margin-top: 8px;
  `;

export const Container = styled.li`
  display: flex;

  & + li {
    margin-top: 20px;
  }
`;

export const NewQuestion = styled.div`
  min-height: 200px;
  background: #fff;
  display: flex;
  flex: 1;
  padding: 20px 30px;
  border: 2px solid #fff;
  border-radius: 5px;
  flex-direction: column;

  p {
    font-size: 24px;
    font-weight: bold;
    color: #7159EB;
    margin-bottom: 20px;
  }

  label {
    color: #3d3d4d;
    font-weight: bold;
    margin-top: 10px;
  }

  span {
    font-size: 14px;
    color: #7159EB;
  }

  input {
    margin-top: 10px;
    margin-bottom: 10px;
    border-width: 0 0 1px;
    border-color: #949494;
    font-size: 18px;
    padding-left: 5px;
    color: #3d3d4d;
    width: 70%;

    &::placeholder {
      color: #a8a8b3;
    }
  }

  button {
    background: #7159EB;
    color: #fff;
    font-weight: bold;
    border: 0;
    padding: 10px;
    width: 20%;
    border-radius: 5px;
    transition: opacity 0.2s;
    margin-top: 20px;

    & + button {
      margin-left: 10px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  button.cancel-btn {
    background: #c53030;
  }

  @media (max-width: 700px) {
    min-height: 120px;

    label svg {
      width: 20px;
    }

    p, label {
      font-size: 16px;
    }

    span {
      font-size: 12px;
    }

    input {
      font-size: 10px;

      &::placeholder {
        font-size: 10px;
      }
    }

    input[type='text'] {
      width: 70%;
      font-size: 12px;
    }
  }

  @media (max-width: 545px) {
    label svg {
      width: 15px;
    }

    p, label {
      font-size: 12px;
    }

    span {
      font-size: 10px;
    }

    span.required-text {
      font-size: 10px;
    }

    input {
      font-size: 8px;

      &::placeholder {
        font-size: 8px;
      }
    }

    input[type='text'] {
      font-size: 10px;
    }

    button {
      font-size: 10px;
      width: 40%;
    }
  }
`;

export const ContainerLoading = styled.div`
  display: flex;
  margin: 100px auto;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #7159EB;

  @media (max-width: 700px) {
    h1 {
      font-size: 32px;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 24px;
      text-align: center;
    }
  }
`;

export const TitleChangeCard = styled.div`
  min-height: 200px;
  background: #fff;
  display: flex;
  width: 70%;
  padding: 20px 30px;
  border: 2px solid #fff;
  border-radius: 5px;
  flex-direction: column;
  margin: 0 auto;

  label {
    color: #3d3d4d;
    font-weight: bold;
    margin-top: 10px;
  }

  input {
    margin: 10px auto;
    border-width: 0 0 1px;
    border-color: #949494;
    font-size: 18px;
    padding-left: 5px;
    color: #3d3d4d;
    width: 80%;

    &::placeholder {
      color: #a8a8b3;
    }
  }

  @media (max-width: 700px) {
    min-height: 120px;

    label svg {
      width: 20px;
    }

    label {
      font-size: 16px;
    }

    input {
      font-size: 10px;

      &::placeholder {
        font-size: 10px;
      }
    }

    input[type='text'] {
      width: 70%;
      font-size: 12px;
    }
  }

  @media (max-width: 545px) {
    label svg {
      width: 15px;
    }

    label {
      font-size: 12px;
    }

    input {
      font-size: 8px;

      &::placeholder {
        font-size: 8px;
      }
    }

    input[type='text'] {
      font-size: 10px;
    }
  }
`;
