function idQuestionCreate(index) {
  if (index.toString().length === 1) {
    return ('Question00' + index);
  } else if (index.toString().length === 2) {
    return ('Question0' + index);
  } else if (index.toString().length === 3) {
    return ('Question00' + index);
  }
}

export default idQuestionCreate;
