import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyABhDVFGTFXMdSAp29duz8Bf2qxA_jfWrc",
  authDomain: "healthinn-1d5a9.firebaseapp.com",
  databaseURL: "https://healthinn-1d5a9.firebaseio.com",
  projectId: "healthinn-1d5a9",
  storageBucket: "healthinn-1d5a9.appspot.com",
  messagingSenderId: "320564138666",
  appId: "1:320564138666:web:7be34aa735b9671e6bb6d3"
};

firebase.initializeApp(firebaseConfig);

export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
