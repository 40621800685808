import styled from 'styled-components';

export const MainContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 40px 20px;
`;

export const PartnersContainer = styled.div`
  display: flex;
  height: 15vw;
  margin: 0 auto;
  max-height: 100px;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  strong {
    color: #a8a8b3;
  }

  div {
    display: flex;
    width: 40%;
    flex: 2;
    height: 100%;
    align-items: center;
    justify-content: center;

    img {
      width: 15%;
      height: auto;
      margin: 0 10px;

      object-fit: contain;
    }
  }

  div.contact {
    justify-content: right;
    flex: 1;

    a {
      display: flex;
      text-decoration: none;
      color: #03c04a;
      border: 2px solid #03c04a;
      border-radius: 30px;
      padding: 15px 10px;
      font-weight: bold;
      align-items: center;
      margin: 0 auto;
      transition: opacity 0.5s;
      transition: background-color 0.5s;

      &:hover {
        opacity: 0.8;
        color: #fff;
        background: #03c04a;
      }
    }
  }

  @media (max-width: 750px) {
    div {
      img {
        width: 20%;
      }
    }

    div.contact {
      font-size: 14px;
    }
  }

  @media (max-width: 530px) {
    div {
      padding-left: 10px;
    }

    div.contact {
      font-size: 10px;
      padding-left: 0;

      a {
        padding: 10px 5px;
        font-weight: bold;
        margin: 40px auto;
        transition: opacity 0.5s;
        transition: background-color 0.5s;
      }
    }
  }

  @media (max-width: 440px) {
    div {
      flex: 2.5;
      padding-left: 5px;
    }

    div.contact {
      flex: 1;
      font-size: 10px;
      padding-left: 0;

      a {
        padding: 8px 5px;
        font-weight: bold;
        margin: 0 auto;
        transition: opacity 0.5s;
        transition: background-color 0.5s;
      }
    }
  }

  @media (max-width: 390px) {
    flex-direction: column;
    height: 30vw;
    max-height: 200px;

    div {
      flex: 1;
      margin: 0;

      img {
        width: 45%;
      }
    }

    div.contact {
      font-size: 12px;
    }
  }
`;

export const ResponseCard = styled.div`
  background: #fff;
  display: flex;
  flex: 1;
  max-width: 700px;
  align-items: center;
  margin: 10px auto;
  padding: 10px 20px;
  border: 0;

  p {
    color: #023d68;
    font-size: 18px;
  }

  span {
    margin-left: 10px;
    font-size: 18px;
    color: #3d3d4d;
  }

  @media (max-width: 700px) {
    p {
      font-size: 16px;
    }

    span {
      margin-left: 5px;
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;

    p {
      font-size: 14px;
    }

    span {
      margin-left: 0;
      margin-top: 10px;
      font-size: 12px;
    }
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  color: #023d68;
  text-align: center;
  margin-bottom: 10px;

  @media (max-width: 700px) {
      font-size: 32px;
  }
  @media (max-width: 500px) {
      font-size: 24px;
  }
`;

export const SubmitMessage = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px auto;
  width: 70%;
  background: #fff;
  padding: 40px 20px;
  border-radius: 5px;
  line-height: 24px;

  > strong {
    font-size: 24px;
    color: #023d68;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    margin-bottom: 30px;

    strong {
      color: #023d68;
    }
  }

  img.recommendations {
    width: 100%;
    border-radius: 10px;
  }

  a {
    display: flex;
    text-decoration: none;
    color: #023d68;
    font-weight: bold;
    align-items: center;
    margin: 0 auto;
    transition: opacity 0.5s;
    transition: transform 0.5s;

    &:hover {
      opacity: 0.8;
      transform: translateX(3px);
    }
  }

  span {
    font-size: 12px;
    font-weight: bold;
    margin-top: 30px;
  }

  @media (max-width: 740px) {
    strong {
    font-size: 18px;
    color: #023d68;
    margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      margin-bottom: 30px;
    }

    a {
      font-size: 12px;
    }

    span {
      font-size: 12px;
    }
  }

  @media (max-width: 425px) {
    width: 90%;
  }
`;

export const Button = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  button {
    background: #023d68;
    color: #fff;
    font-weight: bold;
    border: 0;
    padding: 10px;
    width: 30%;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 740px) {
    button {
      font-size: 12px;
      width: 40%;
    }
  }

  @media (max-width: 425px) {
    button {
      width: 50%;
    }
  }
`;

export const NoContent = styled.div`
  width: 70%;
  height: 150px;
  background: #fff;
  color: #023d68;
  border: 0;
  border-radius: 10px;
  font-size: 24px;
  margin: 30px auto;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
