import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, FormControl, FormControlLabel, RadioGroup, Radio, FormGroup, Checkbox } from '@material-ui/core';
import firebase from '../../firebase';

import crypto from 'crypto';

import idQuestionCreate from '../../utils/idQuestionCreate';

import { Header, Form, QuestionCard, Container, NewQuestion, ContainerLoading, TitleChangeCard, MainContainer } from './styles';

import EditButtons from '../../components/EditButtons';

const FormSO = () => {
  const history = useHistory();

  const [formId, setFormId] = useState('');
  const [formCreatedAt, setFormCreatedAt] = useState('');
  const [formTitle, setFormTitle] = useState('');
  const [formSubTitle, setFormSubTitle] = useState('');

  const [formConfig, setFormConfig] = useState([]);

  const [loading, setLoading] = useState(false);
  const [changeTitle, setChangeTitle] = useState(false);

  const [questionIdDependant, setQuestionIdDependant] = useState('');
  const [newQuestionId, setNewQuestionId] = useState('');

  const [newQuestionDependant, setNewQuestionDependant] = useState('Não');
  const [newQuestionDepAnswers, setNewQuestionDepAnswers] = useState('');
  const [newQuestionType, setNewQuestionType] = useState('text');
  const [newQuestionTitle, setNewQuestionTitle] = useState('');
  const [newQuestionSubInfo, setNewQuestionSubInfo] = useState('');
  const [newQuestionRequired, setNewQuestionRequired] = useState('Não');
  const [newQuestionPossibleAnswers, setNewQuestionPossibleAnswers] = useState('');

  const [editQuestionId, setEditQuestionId] = useState('');
  const [editedQuestionTitle, setEditedQuestionTitle] = useState('');
  const [editedQuestionSubInfo, setEditedQuestionSubInfo] = useState('');
  const [editedQuestionRequired, setEditedQuestionRequired] = useState('Não');
  const [editedQuestionPossibleAnswers, setEditedQuestionPossibleAnswers] = useState('');

  useEffect(() => {
    const formQuestionsSetup = [];

    setLoading(true);

    firebase.database().ref("forms/").orderByKey().limitToLast(1).once('value')
    .then(response => {
      response.forEach(form => {
        const formSetup = form.val().questions;
        setFormId(form.key);
        setFormCreatedAt(form.val().createdAt);
        setFormTitle(form.val().title);
        setFormSubTitle(form.val().subtitle);

        const arrayQuestionsId = Object.keys(formSetup);

        for (let i = 0; i < arrayQuestionsId.length; i++) {
            formQuestionsSetup.push(formSetup[arrayQuestionsId[i]]);
        }

        setFormConfig(formQuestionsSetup);
        setLoading(false)
      })
    })
    .catch(err => {
      setLoading(false);
      alert("Erro ao buscar formulário do banco de dados! Tente novamente.");
    });
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const finalFormConfig = {
      title: formTitle,
      subtitle: formSubTitle,
      createdAt: new Date().toISOString(),
      questions: {},
    }

    for (let i = 0; i < formConfig.length; i++) {
      const questionId = idQuestionCreate(i);
      const uniqueQuestionId = formConfig[i].idQuestion;

      finalFormConfig.questions[questionId] = {...formConfig[i], idQuestion: uniqueQuestionId};
    }

    try {
      const formRef = await firebase.database().ref("forms/").push();

      await firebase.database().ref(`forms/${formRef.key}`)
      .update({...finalFormConfig, formId: formRef.key});

      alert('Novo formulário salvo com succeso!');
      history.push('/form/idTeste');
    } catch (err) {
      alert('Ocorreu um erro ao salvar no banco de dados, tente novamente.')
    }

  }, [formConfig, history, formTitle, formSubTitle]);

  const handleAddquestion = useCallback((questionId) => {
    const questionindex = formConfig.findIndex(question => question.idQuestion === questionId);

    const newQuestion = {
      type: 'newQuestion',
      required: false,
      title: '',
      subinfo: null,
      possibleAnswers: '',
      idQuestion: crypto.randomBytes(8).toString('HEX'),
      dependant: {
          answer: [],
          idQuestion: ''
      }
    };

    const newFormConfig = [...formConfig];

    newFormConfig.splice(questionindex + 1, 0, newQuestion);

    setNewQuestionId(newQuestion.idQuestion);
    setFormConfig(newFormConfig);
    setQuestionIdDependant(questionId);
  }, [formConfig]);

  const handleDeleteQuestion = useCallback((questionId) => {
    const newFormConfig = formConfig.filter(question => {
      if (question.dependant && question.dependant.idQuestion === questionId) {
        return false;
      } else if (question.idQuestion === questionId) {
        return false;
      }
      return true;
    });

    console.log(newFormConfig);

    setFormConfig(newFormConfig);
  }, [formConfig]);

  const handleUpdateQuestion = useCallback((questionId) => {
    const questionindex = formConfig.findIndex(question => question.idQuestion === questionId);
    const question = formConfig.find(question => question.idQuestion === questionId);

    const questionEdit = {
      type: 'edit',
      idQuestion: crypto.randomBytes(8).toString('HEX'),
    };

    const newFormConfig = [...formConfig];

    newFormConfig.splice(questionindex + 1, 0, questionEdit);

    setEditQuestionId(questionId);

    setEditedQuestionPossibleAnswers(question.possibleAnswers);
    setEditedQuestionRequired(question.required);
    setEditedQuestionTitle(question.title);
    setEditedQuestionSubInfo(question.subinfo);

    setFormConfig(newFormConfig);
  }, [formConfig]);

  const findDependantQuestion = useCallback((questionId) => {
    const foundQuestion = formConfig.find(question => question.idQuestion === questionId ? true : false);

    if (foundQuestion) {
      return `${foundQuestion.title}`;
    }

    return 'Not Found';
  }, [formConfig]);

  const handleCancelNewQuestion = useCallback((questionId) => {
    const questionindex = formConfig.findIndex(question => question.idQuestion === questionId);

    const newFormConfig = [...formConfig];

    newFormConfig.splice(questionindex, 1);

    setFormConfig(newFormConfig);
    setNewQuestionId('');
    setQuestionIdDependant('');
    setNewQuestionDependant('Não');
    setNewQuestionRequired('Não');
    setEditedQuestionRequired('Não');
  }, [formConfig]);

  const handleChange = useCallback((e) => {
    const key = e.target.name;
    const value = e.target.value;

    if (key === 'dependant') {
      setNewQuestionDependant(value);
    }
    else if (key === 'dependant-answers') {
      setNewQuestionDepAnswers(value);
    }
    else if (key === 'type') {
      setNewQuestionType(value);
    }
    else if (key === 'title') {
      setNewQuestionTitle(value);
    }
    else if (key === 'sub-info') {
      setNewQuestionSubInfo(value);
    }
    else if (key === 'required') {
      setNewQuestionRequired(value);
    }
    else if (key === 'possible-answers') {
      setNewQuestionPossibleAnswers(value);
  }

  }, []);

  const handleChangeEdit = useCallback((e) => {
    const key = e.target.name;
    const value = e.target.value;

    if (key === 'title') {
      setEditedQuestionTitle(value);
    }
    else if (key === 'sub-info') {
      setEditedQuestionSubInfo(value);
    }
    else if (key === 'required') {
      setEditedQuestionRequired(value === 'Não' ? false : true);
    }
    else if (key === 'possible-answers') {
      setEditedQuestionPossibleAnswers(value);
  }

  }, []);

  const handleCreateQuestion = useCallback(() => {

    const newForm = formConfig.map(question => {
      if (question.idQuestion === newQuestionId) {
        return {
          type: newQuestionType,
          required: newQuestionRequired === 'Sim' ? true : false,
          title: newQuestionTitle,
          subinfo: newQuestionSubInfo ? newQuestionSubInfo : null,
          possibleAnswers: newQuestionPossibleAnswers.length ? newQuestionPossibleAnswers.split(', ') : 'any',
          idQuestion: newQuestionId,
          dependant: newQuestionDependant === 'Sim'
          ? {
            answer: newQuestionDepAnswers.split(', '),
            idQuestion: questionIdDependant
          }
          : null,
        };
      }

      return question;
    });

    console.log(newForm);
    setFormConfig(newForm);
    setNewQuestionDependant('Não');
    setNewQuestionDepAnswers('');
    setNewQuestionType('text');
    setNewQuestionTitle('');
    setNewQuestionSubInfo('');
    setNewQuestionRequired('Não');
    setNewQuestionPossibleAnswers('');
    setQuestionIdDependant('');
    setNewQuestionId('');
  }, [formConfig, newQuestionId, newQuestionRequired, newQuestionType, newQuestionDepAnswers, newQuestionDependant, newQuestionPossibleAnswers, newQuestionSubInfo, newQuestionTitle, questionIdDependant]);

  const handleSaveEditedQuestion = useCallback(() => {

    const newForm = formConfig.map(question => {
      if (question.idQuestion === editQuestionId) {
        return {
          ...question,
          title: editedQuestionTitle,
          subinfo: !editedQuestionSubInfo ? null : editedQuestionSubInfo,
          required: editedQuestionRequired,
          possibleAnswers: !Array.isArray(editedQuestionPossibleAnswers) && editedQuestionPossibleAnswers !== 'any' ? editedQuestionPossibleAnswers.split(', ') : editedQuestionPossibleAnswers,
        }
      }

      return question;
    }).filter(question => question.type !== 'edit');

    console.log(newForm);
    setEditQuestionId('');

    setEditedQuestionPossibleAnswers('');
    setEditedQuestionRequired('Não');
    setEditedQuestionTitle('');
    setEditedQuestionSubInfo('');

    setFormConfig(newForm);
  }, [formConfig, editQuestionId, editedQuestionRequired, editedQuestionPossibleAnswers, editedQuestionSubInfo, editedQuestionTitle]);

  return (
    <>
    <MainContainer>
      <Header>
        <h1>{formTitle}</h1>
        <p>{formSubTitle}</p>
        {!loading && <button type="button" onClick={() => setChangeTitle(!changeTitle)}>Editar título ou subtítulo</button>}
        {changeTitle && (
                  <Container key="titleSubtitleChange">
                    <TitleChangeCard>
                      <label htmlFor="title">Título:</label>
                      <input
                        name="title"
                        type="text"
                        placeholder='ex.: Título'
                        value={formTitle}
                        onChange={(e) => setFormTitle(e.target.value)}
                      />

                      <label htmlFor="sub-info">Sub-título:</label>
                      <input
                        name="subtitle"
                        type="text"
                        placeholder="ex.: esse formulário é lindo"
                        value={formSubTitle}
                        onChange={(e) => setFormSubTitle(e.target.value)}
                      />
                    </TitleChangeCard>

                  </Container>
              )
              }
        <p className="edit-text">MODO EDIÇÃO: faça as alterações desejadas e salve este formulário clicando no botão ao final da página.</p>
        <p className="edit-text">FormId: {formId}</p>
        <p className="edit-text">Criado em: {formCreatedAt}</p>
      </Header>
      {loading && (
        <ContainerLoading>
          <h1>Carregando formulário...</h1>
        </ContainerLoading>
      )}
      <Form>
        <ul>
          {formConfig.map(question => {
            if (question.type === 'text') {
                return (
                  <Container key={question.idQuestion}>
                    <QuestionCard key={question.idQuestion} questionId={question.idQuestion}>
                    {question.dependant && (
                      <span className="dependant">Dependência: {findDependantQuestion(question.dependant.idQuestion)}</span>
                    )}
                    <p>{question.title}</p>
                    {question.required && <span className="required-text">**obrigatória</span>}
                    {question.subinfo && <span>{question.subinfo}</span>}
                      <input
                        name={question.idQuestion}
                        type={question.type}
                        placeholder='Sua Resposta'
                      />
                    </QuestionCard>

                    <EditButtons
                      questionId={question.idQuestion}
                      onClickAdd={() => handleAddquestion(question.idQuestion)}
                      onClickDelete={() => handleDeleteQuestion(question.idQuestion)}
                      onClickUpdate={() => handleUpdateQuestion(question.idQuestion)}
                    />
                  </Container>
                );
            }

            else if (question.type === 'date') {
              return (
                <Container key={question.idQuestion}>
                  <QuestionCard key={question.idQuestion} questionId={question.idQuestion}>
                    {question.dependant && (
                      <span className="dependant">Dependência: {findDependantQuestion(question.dependant.idQuestion)}</span>
                    )}
                    <p>{question.title}</p>
                    {question.required && <span className="required-text">**obrigatória</span>}
                    {question.subinfo && <span>{question.subinfo}</span>}
                    <TextField
                      name={question.idQuestion}
                      className="date"
                      label="Data"
                      type={question.type}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </QuestionCard>

                  <EditButtons
                    questionId={question.idQuestion}
                    onClickAdd={() => handleAddquestion(question.idQuestion)}
                    onClickDelete={() => handleDeleteQuestion(question.idQuestion)}
                    onClickUpdate={() => handleUpdateQuestion(question.idQuestion)}
                  />
                </Container>
              );
            }

            else if (question.type === 'radio-button') {
              return (
                <Container key={question.idQuestion}>
                  <QuestionCard key={question.idQuestion} questionId={question.idQuestion}>
                    {question.dependant && (
                      <span className="dependant">Dependência: {findDependantQuestion(question.dependant.idQuestion)}</span>
                    )}
                    <p>{question.title}</p>
                    {question.required && <span className="required-text">**obrigatória</span>}
                    {question.subinfo && <span>{question.subinfo}</span>}
                    <FormControl component="fieldset" required >
                      <RadioGroup name={question.idQuestion}>
                        {question.possibleAnswers.map(answer => (
                          <FormControlLabel key={answer} value={answer} control={<Radio />} label={answer === 'Outro' ? 'Sim' : answer} />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </QuestionCard>

                  <EditButtons
                    questionId={question.idQuestion}
                    onClickAdd={() => handleAddquestion(question.idQuestion)}
                    onClickDelete={() => handleDeleteQuestion(question.idQuestion)}
                    onClickUpdate={() => handleUpdateQuestion(question.idQuestion)}
                  />
                </Container>
              );
            }
            else if (question.type === 'checkbox') {
              return (
                <Container key={question.idQuestion}>
                  <QuestionCard key={question.idQuestion} questionId={question.idQuestion}>
                    {question.dependant && (
                      <span className="dependant">Dependência: {findDependantQuestion(question.dependant.idQuestion)}</span>
                    )}
                    <p>{question.title}</p>
                    {question.required && <span className="required-text">**obrigatória</span>}
                    {question.subinfo && <span>{question.subinfo}</span>}
                    <FormGroup>
                      {question.possibleAnswers.map(answer => (
                        <FormControlLabel
                        key={answer}
                        control={<Checkbox name={question.idQuestion} value={answer} />}
                        label={answer}
                      />
                      ))}
                    </FormGroup>
                  </QuestionCard>

                  <EditButtons
                    questionId={question.idQuestion}
                    onClickAdd={() => handleAddquestion(question.idQuestion)}
                    onClickDelete={() => handleDeleteQuestion(question.idQuestion)}
                    onClickUpdate={() => handleUpdateQuestion(question.idQuestion)}
                  />
                </Container>
              );
            }

            else if (question.type === 'edit') {
              return (
                  <Container key={question.type}>
                    <NewQuestion key={question.type} questionId={question.type}>
                      <p>Editando pergunta</p>

                      <label htmlFor="title">Pergunta:</label>
                      <input
                        name="title"
                        type="text"
                        placeholder='ex.: Título'
                        value={editedQuestionTitle}
                        onChange={handleChangeEdit}
                      />

                      <label htmlFor="sub-info">Sub-texto:</label>
                      <input
                        name="sub-info"
                        type="text"
                        placeholder="ex.: o sentido não precisa ser um número ou palavras"
                        value={editedQuestionSubInfo ? editedQuestionSubInfo : ''}
                        onChange={handleChangeEdit}
                      />

                      <label htmlFor="required">Obrigatório?</label>
                      <FormControl component="fieldset" required >
                        <RadioGroup name="required" value={editedQuestionRequired ? "Sim" : "Não"} onChange={handleChangeEdit}>
                          <FormControlLabel value="Não" control={<Radio />} label="Não" />
                          <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                        </RadioGroup>
                      </FormControl>

                      {editedQuestionPossibleAnswers !== 'any' && (
                        <>
                        <label htmlFor="possible-answers">Possíveis Resposta:</label>
                        <input
                          name="possible-answers"
                          type="text"
                          placeholder='ex.: opção1, opção2, opção3'
                          value={Array.isArray(editedQuestionPossibleAnswers) ? editedQuestionPossibleAnswers.join(', ') : editedQuestionPossibleAnswers}
                          onChange={handleChangeEdit}
                        />
                        </>
                      )}

                      <div>
                        <button type="button" onClick={handleSaveEditedQuestion}>Salvar</button>
                        <button type="button" className="cancel-btn" onClick={() => handleCancelNewQuestion(question.idQuestion)}>Cancelar</button>
                      </div>

                    </NewQuestion>

                  </Container>
              );
            }
            else if (question.type === 'newQuestion') {
              return (
                  <Container key={question.idQuestion}>
                    <NewQuestion key={question.idQuestion} questionId={question.idQuestion}>
                      <p>Nova pergunta</p>

                      <label htmlFor="dependant" >É dependente da pergunta: {findDependantQuestion(questionIdDependant)}</label>
                      <FormControl component="fieldset" required >
                        <RadioGroup name="dependant" value={newQuestionDependant} onChange={handleChange}>
                          <FormControlLabel value="Não" control={<Radio />} label="Não" />
                          <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                        </RadioGroup>
                      </FormControl>

                      <label htmlFor="dependant-answers">Se ela for dependente, ela será dependente de qual ou quais respostas?</label>
                      <span>Caso não, deixe em branco. Caso seja mais de uma resposta separe por vírgula e espaço</span>
                      <input
                        name="dependant-answers"
                        type="text"
                        placeholder='ex.: Doença, Lesão'
                        onChange={handleChange}
                      />

                      <label htmlFor="title">Qual é o texto da pergunta?</label>
                      <input
                        name="title"
                        type="text"
                        placeholder='ex.: Qual é o sentido da vida?'
                        onChange={handleChange}
                      />

                      <label htmlFor="sub-info">Tem alguma informação adicional sobre a pergunta?</label>
                      <span>Caso não, deixe em branco</span>
                      <input
                        name="sub-info"
                        type="text"
                        placeholder="ex.: o sentido não precisa ser um número ou palavras"
                        onChange={handleChange}
                      />

                      <label htmlFor="required">Obrigatório?</label>
                      <FormControl component="fieldset" required >
                        <RadioGroup name="required" value={newQuestionRequired} onChange={handleChange}>
                          <FormControlLabel value="Não" control={<Radio />} label="Não" />
                          <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                        </RadioGroup>
                      </FormControl>

                      <label htmlFor="type">Qual é o tipo da resposta?</label>
                      <FormControl component="fieldset" required >
                        <RadioGroup name="type" value={newQuestionType} onChange={handleChange}>
                          <FormControlLabel value={'text'} control={<Radio />} label="Texto" />
                          <FormControlLabel value={'date'} control={<Radio />} label="Data" />
                          <FormControlLabel value={'radio-button'} control={<Radio />} label="Botões de rádio" />
                          <FormControlLabel value={'checkbox'} control={<Radio />} label="Checkbox" />
                        </RadioGroup>
                      </FormControl>

                      <label htmlFor="possible-answers">Possíveis Resposta:</label>
                      <span>Caso seja botões de rádio ou checkbox, coloque as opções separadas por vírgula e espaço</span>
                      <input
                        name="possible-answers"
                        type="text"
                        placeholder='ex.: opção1, opção2, opção3'
                        onChange={handleChange}
                      />

                      <div>
                        <button type="button" onClick={handleCreateQuestion}>Criar</button>
                        <button type="button" className="cancel-btn" onClick={() => handleCancelNewQuestion(question.idQuestion)}>Cancelar</button>
                      </div>

                    </NewQuestion>

                  </Container>
              );
            }

            return true;
          })}
        </ul>
        <button type="submit" onClick={handleSubmit}>Salvar</button>
      </Form>
    </MainContainer>
    </>
  )
};

export default FormSO;
