import styled from "styled-components";

export const MainContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 40px 20px;
`;

export const Header = styled.header`
  text-align: center;

  h1 {
    font-size: 46px;
    color: #7159eb;
  }

  p {
    font-size: 20px;
    font-weight: bold;
    color: #3d3d4d;
    margin-top: 20px;
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ul {
    list-style: none;
    display: flex;
    width: 70%;
    margin: 30px auto;
    flex-direction: column;
  }

  button {
    background: #7159eb;
    color: #fff;
    font-weight: bold;
    border: 0;
    padding: 10px;
    width: 40%;
    border-radius: 5px;
    transition: opacity 0.2s;

    &:hover:not(:disabled) {
      opacity: 0.8;
    }

    &:disabled {
      background-color: #ccc;
    }
  }

  @media (max-width: 500) {
    ul {
      width: 100%;
    }
  }
`;

export const QuestionCard = styled.li`
  min-height: 200px;
  background: #fff;
  display: flex;
  flex: 1;
  padding: 20px 30px;
  border: 2px solid #fff;
  border-radius: 5px;
  flex-direction: column;

  border-color: ${(props) => {
    const inputError = props.hasError;
    const questionId = props.questionId;
    if (!inputError) {
      return "#fff";
    } else {
      const [questionIdError] = inputError.split("-");
      if (questionIdError === questionId) {
        return "#c53030";
      }
    }
  }};

  & + li {
    margin-top: 20px;
  }

  p {
    font-size: 24px;
    color: #3d3d4d;
  }

  span {
    font-size: 18px;
    color: #a8a8b3;
  }

  span.subinfo {
    margin-bottom: 10px;
  }

  span.required-text {
    display: inline-flex;
    margin-bottom: 20px;
    font-size: 14px;
    color: #c53030;
  }

  input[type="text"] {
    margin-top: auto;
    margin-bottom: 10px;
    border: 0;
    border-bottom: 1px solid #949494;
    border-radius: 0;
    font-size: 18px;
    padding: 5px 5px 2px 5px;
    color: #3d3d4d;
    width: 50%;

    &:focus {
      border-bottom-color: #7159eb;
    }

    &::placeholder {
      color: #a8a8b3;
    }
  }

  .date {
    margin-top: auto;
    margin-bottom: 10px;
    border: 0;
    font-size: 18px;
    padding: 5px;
    color: #3d3d4d;
    width: 50%;
  }

  @media (max-width: 700px) {
    min-height: 120px;

    label svg {
      width: 20px;
    }

    p {
      font-size: 16px;
    }

    span {
      font-size: 12px;
    }

    span.required-text {
      font-size: 10px;
    }

    input {
      font-size: 12px;

      &::placeholder {
        font-size: 12px;
      }
    }

    input[type="text"] {
      width: 70%;
      font-size: 12px;
    }

    .date {
      width: 70%;
    }
  }

  @media (max-width: 400px) {
    padding: 10px 20px;

    label svg {
      width: 15px;
    }

    input[type="text"] {
      width: 90%;
    }

    .date {
      width: 90%;
    }
  }
`;

export const Error = styled.span`
  display: block;
  color: #c53030;
  margin-top: 8px;

  @media (max-width: 700px) {
    margin-top: 5px;
    font-size: 12px;
  }

  @media (max-width: 375px) {
    margin-top: 2px;
    font-size: 10px;
  }
`;

export const ContainerLoading = styled.div`
  display: flex;
  margin: 100px auto;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #7159eb;

  @media (max-width: 700px) {
    h1 {
      font-size: 32px;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 24px;
      text-align: center;
    }
  }
`;
