import React, { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router-dom";
import {
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { FiArrowRight } from "react-icons/fi";

import api from "../../services/api";
import formCovid from "../../utils/formSetup.json";
import { cpfMask, telefoneMask } from "../../utils/InputMasks";
import DateMask from "../../utils/dateMask";

import idQuestionCreate from "../../utils/idQuestionCreate";

// import imgLogoAmigosPoli from "../../assets/amigos_da_poli_logo.png";
// import imgLogoPoliUsp from "../../assets/poli_usp_logo.jpg";
// import imgLogoMedfusion from "../../assets/medfusion_logo.png";
// import imgLogoHealthinn from "../../assets/healthinn_logo.png";
// import imgLogoWhatsapp from '../../assets/whatsapp_logo.png';

import {
  Header,
  Form,
  Error,
  QuestionCard,
  WelcomeMessage,
  TermsOfUseMessage,
  // PartnersContainer,
  MainContainer,
} from "./styles";

const FormCovid = () => {
  const history = useHistory();

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [cpfUser, setCpfUser] = useState("");
  const [cpfSubmit, setCpfSubmit] = useState(false);
  const [telUser, setTelUser] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCompany, setUserCompany] = useState("");

  const [appRegisteredBirth, setAppRegisteredBirth] = useState(false);
  const [appRegistered, setAppRegistered] = useState(false);
  const [missingUserEmail, setMissingUserEmail] = useState(false);
  const [missingUserCompany, setMissingUserCompany] = useState(false);

  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [formId, setFormId] = useState("");
  const [formConfig, setFormConfig] = useState([]);
  const [formResponses, setFormResponses] = useState([]);
  const [inputError, setInputError] = useState("");
  const [refs, setRefs] = useState({});

  function createDefaultResponses(formSetup) {
    const responseSetup = [];

    for (let i = 0; i < formSetup.length; i++) {
      if (formSetup[i].type === "checkbox") {
        const arrayPossibleAnswers = formSetup[i].possibleAnswers;

        const arrayPABoolean = arrayPossibleAnswers.map((answer) => {
          return { [answer]: false, value: answer };
        });

        responseSetup.push({ [formSetup[i].idQuestion]: arrayPABoolean });
      } else {
        responseSetup.push({ [formSetup[i].idQuestion]: "Sem Resposta" });
      }
    }

    return responseSetup;
  }

  useEffect(() => {
    const formQuestionsSetup = [];

    const formSetup = formCovid.formCovidId.questions;

    setFormId("formCovidId");

    const arrayQuestionsId = Object.keys(formSetup);

    for (let i = 0; i < arrayQuestionsId.length; i++) {
      formQuestionsSetup.push(formSetup[arrayQuestionsId[i]]);
    }

    setFormConfig(formQuestionsSetup);

    const refs = formQuestionsSetup.reduce((acc, question) => {
      acc[question.idQuestion] = createRef();
      return acc;
    }, {});

    setRefs(refs);

    const responseSetup = createDefaultResponses(formQuestionsSetup);

    setFormResponses(responseSetup);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    for (let i = 0; i < formResponses.length; i++) {
      const [key] = Object.keys(formResponses[i]);
      const { required } = formConfig.find(
        (question) => question.idQuestion === key
      );

      if (
        (!formResponses[i][key] ||
          formResponses[i][key] === "Sem Resposta" ||
          formResponses[i][key] === "Outro") &&
        required &&
        key !== "Question005" &&
        refs[key].current
      ) {
        setInputError(`${key}-Necessário preencher este campo!`);
        refs[key].current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return;
      }

      if (Array.isArray(formResponses[i][key])) {
        const checkedAnswers = formResponses[i][key].filter((answer) => {
          const [key] = Object.keys(answer);
          if (answer[key]) {
            return true;
          } else {
            return false;
          }
        });

        if (!checkedAnswers.length && required && refs[key].current) {
          setInputError(`${key}-Necessário preencher estes campos!`);
          refs[key].current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          return;
        }
      }
    }

    const finalFormResponses = {
      userId: userId,
      formId: formId,
      createdAt: new Date().toISOString(),
      answers: {},
    };

    for (let i = 0; i < formResponses.length; i++) {
      const questionIdNumber = idQuestionCreate(i);

      const [key] = Object.keys(formResponses[i]);

      if (
        key === "Question001" &&
        !appRegisteredBirth &&
        formResponses[i][key].includes("-")
      ) {
        finalFormResponses.answers[questionIdNumber] = DateMask(
          formResponses[i][key]
        );
      } else if (key === "Question013" || key === "Question014") {
        if (formResponses[i][key].includes(".")) {
          finalFormResponses.answers[questionIdNumber] = parseFloat(
            formResponses[i][key]
          );
        } else if (formResponses[i][key].includes(",")) {
          finalFormResponses.answers[questionIdNumber] = parseFloat(
            formResponses[i][key].replace(",", ".")
          );
        } else {
          finalFormResponses.answers[questionIdNumber] = parseFloat(
            formResponses[i][key]
          );
        }
      } else {
        finalFormResponses.answers[questionIdNumber] = formResponses[i][key];
      }
    }

    setInputError("");
    if (!appRegistered) {
      const user = {
        nome: userName,
        tel: telUser,
        nascimento: finalFormResponses.answers["Question001"],
        email: userEmail,
        cargo: 'Não cadastrado',
        cpf: cpfUser,
        empresa: !userCompany ? 'Não cadastrado' : userCompany,
      };

      try {
        setLoading(true);

        const { data: newUser } = await api.post("/user/new", user);

        finalFormResponses.userId = newUser.key;

        const { data: formData } = await api.post("/submit-covidform/bahia", finalFormResponses);

        setLoading(false);

        history.push("/submitted-form/submit", {
          responses: finalFormResponses,
          questions: formConfig,
          mode: 'score',
          score: formData.score,
        });
      } catch (err) {
        setLoading(false);
        alert("Ocorreu um erro ao enviar seu formulário. Tente novamente!");
      }

      return;
    } else if (missingUserCompany || missingUserEmail) {
      const user = {
        key: userId,
        nome: userName,
        tel: telUser,
        nascimento: finalFormResponses.answers["Question001"],
        email: userEmail,
        cpf: cpfUser,
        empresa: !userCompany ? 'Não cadastrado' : userCompany,
      };

      try {
        setLoading(true);

        const { data } = await api.post("/user/update", user);

        if (data.error) throw new Error("erro na requisição");

        const { data: formData } = await api.post("/submit-covidform/bahia", finalFormResponses);

        setLoading(false);

        history.push("/submitted-form/submit", {
          responses: finalFormResponses,
          questions: formConfig,
          mode: 'score',
          score: formData.score,
        });
      } catch (err) {
        setLoading(false);
        alert("Ocorreu um erro ao enviar seu formulário. Tente novamente!");
      }
    } else {
      try {
        setLoading(true);

        const { data: formData } = await api.post("/submit-covidform/bahia", finalFormResponses);

        setLoading(false);

        history.push("/submitted-form/submit", {
          responses: finalFormResponses,
          questions: formConfig,
          mode: 'score',
          score: formData.score,
        });
      } catch (err) {
        setLoading(false);
        alert("Ocorreu um erro ao enviar seu formulário. Tente novamente!");
      }
    }
  }

  function handleChange(e) {
    const propName = e.target.name;
    let changedValue = "";

    if (e.target.type === "cpf") {
      changedValue = cpfMask(e.target.value);
    } else if (e.target.type === "tel") {
      changedValue = telefoneMask(e.target.value);
    } else {
      changedValue = e.target.value;
    }

    const refs = formConfig.reduce((acc, question) => {
      acc[question.idQuestion] = createRef();
      return acc;
    }, {});

    setRefs(refs);

    const formResponsesUpdated = formResponses.map((response) => {
      if (response.hasOwnProperty(propName)) {
        if (Array.isArray(response[propName])) {
          const answerOther = response[propName].find((answer) =>
            answer.hasOwnProperty(changedValue)
          );

          let updatedAnswers = [];
          if (!answerOther) {
            updatedAnswers = response[propName].map((answer) => {
              return answer.hasOwnProperty("Outro")
                ? { Outro: true, value: changedValue }
                : answer;
            });
          } else {
            updatedAnswers = response[propName].map((answer) => {
              return answer.hasOwnProperty(changedValue)
                ? { [changedValue]: e.target.checked, value: changedValue }
                : answer;
            });
          }

          const checkedAnswers = updatedAnswers.filter((answer) => {
            const [key] = Object.keys(answer);
            if (answer[key]) {
              return true;
            } else {
              return false;
            }
          });

          const { required } = formConfig.find(
            (question) => question.idQuestion === propName
          );

          if (!checkedAnswers.length && required && propName !== 'Question005') {
            setInputError(`${propName}-Necessário preencher estes campos!`);
          } else {
            setInputError("");
          }

          return { [propName]: updatedAnswers };
        } else {
          const { required } = formConfig.find(
            (question) => question.idQuestion === propName
          );

          if (changedValue && changedValue !== "Outro") {
            setInputError("");
          } else if (required) {
            setInputError(`${propName}-Necessário preencher este campo!`);
          }
          return { [propName]: changedValue };
        }
      } else {
        return response;
      }
    });

    setFormResponses(formResponsesUpdated);
  }

  function checkError(questionId) {
    if (!inputError) {
      return false;
    } else {
      const [questionIdError] = inputError.split("-");
      if (questionIdError === questionId && questionId !== 'Question005') {
        return true;
      } else {
        return false;
      }
    }
  }

  function checkAnswer(questionId) {
    if (formResponses.length && formConfig.length) {
      const question = formConfig.find((question) => {
        if (question.idQuestion === questionId) {
          return true;
        } else {
          return false;
        }
      });
      if (question.type === "radio-button-other") {
        const answersDiffFromOther = question.possibleAnswers.filter(
          (answer) => answer !== "Outro"
        );

        const answerObject = formResponses.find((answer) => {
          if (answer.hasOwnProperty(questionId)) {
            return true;
          } else {
            return false;
          }
        });

        const value = answerObject[questionId];

        if (!answersDiffFromOther.includes(value) && value !== "Sem Resposta") {
          return true;
        }
        return false;
      } else if (question.type === "checkbox") {
        const arrayAnswers = formResponses.find((response) => {
          if (response.hasOwnProperty(questionId)) {
            return true;
          }
          return false;
        });

        const answerOther = arrayAnswers[questionId].find((answer) =>
          answer.hasOwnProperty("Outro")
        );

        let isOtherChecked = false;

        if (answerOther) {
          isOtherChecked = answerOther["Outro"];
        }

        if (isOtherChecked) {
          return true;
        }
        return false;
      }
      return "";
    }
  }

  async function handleCPFSubmit(e, questionId) {
    //condição caso cpf não seja válido inputError('cpf inválido')
    e.preventDefault();

    try {
      if (cpfUser === "" || cpfUser.length < 13) {
        setCpfSubmit(false);
        setInputError(`${questionId}-Necessário preencher este campo!`);
        return;
      }

      setLoading(true);
      const { data: findUser } = await api.post("/check-cpf", { cpf: cpfUser });
      setLoading(false);

      if (!findUser.error) {
        setInputError("");
        setCpfSubmit(true);

        setAppRegistered(true);
        setUserId(findUser.key);

        if (!findUser.email) setMissingUserEmail(true);

        if (!findUser.empresa) setMissingUserCompany(true);

        setUserName(findUser.nome);
        setTelUser(findUser.tel);
        setUserEmail(findUser.email ? findUser.email : "");
        setUserCompany(findUser.empresa ? findUser.empresa : "");

        if (findUser.nascimento) {
          setAppRegisteredBirth(true);
        }

        const formResponsesUpdated = formResponses.map((response) => {
          if (response.hasOwnProperty("Question000")) {
            response["Question000"] = findUser.cpf;
          } else if (response.hasOwnProperty("Question001")) {
            if (findUser.nascimento) {
              response["Question001"] = findUser.nascimento;
            }
          } else if (response.hasOwnProperty("Question002")) {
            response["Question002"] = findUser.nome;
          } else if (response.hasOwnProperty("Question003")) {
            response["Question003"] = findUser.tel;
          } else if (response.hasOwnProperty("Question004")) {
            response["Question004"] = findUser.email;
          } else if (response.hasOwnProperty("Question005")) {
            response["Question005"] = findUser.empresa;
          }

          return response;
        });

        setFormResponses(formResponsesUpdated);
      } else {
        const formResponsesUpdated = formResponses.map((response) => {
          if (response.hasOwnProperty("Question000")) {
            response["Question000"] = cpfUser;
          }
          return response;
        });

        setFormResponses(formResponsesUpdated);

        setInputError("");
        setCpfSubmit(true);
        setMissingUserEmail(true);
        setMissingUserCompany(true);
      }
    } catch (err) {
      setLoading(false);

      if (err.response.data.error === "CPF inválido") {
        alert(err.response.data.error);
      } else if (err.response.status === 500) {
        alert(err.response.data.error);
      } else {
        const formResponsesUpdated = formResponses.map((response) => {
          if (response.hasOwnProperty("Question000")) {
            response["Question000"] = cpfUser;
          }
          return response;
        });

        setFormResponses(formResponsesUpdated);

        setInputError("");
        setCpfSubmit(true);
        setMissingUserEmail(true);
        setMissingUserCompany(true);
      }
    }
  }

  return (
    <>
      {/* <PartnersContainer>
        <div>
          <img src={imgLogoPoliUsp} alt="Poli USP" />
          <img src={imgLogoAmigosPoli} alt="Amigos da Poli" />
          <img src={imgLogoMedfusion} alt="Medfusion" />
          <img src={imgLogoHealthinn} alt="Health.Inn" />
        </div>

        <div className="contact">
          <a href="https://wa.me/5511933383995" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp fa-lg" style={{marginRight: 5}}></i>
            (11) 93338-3995
          </a>
        </div>
      </PartnersContainer> */}
      <MainContainer>
        <Header>
          <h1>Onyma - Acompanhamento COVID-19</h1>

          {!acceptedTerms && (
            <TermsOfUseMessage>
              <strong>Olá, você está na Onyma.</strong>

              <strong>
                Antes que possamos cuidar de você, é necessário que você leia e
                aceite os Termos de Uso. Para isso, clique no link abaixo.
              </strong>

              <a
                href="http://healthinn.com.br/termos"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ir para Termos de Uso
                <FiArrowRight />
              </a>

              <button
                type="button"
                onClick={() => setAcceptedTerms(!acceptedTerms)}
              >
                Li e Aceito
              </button>
            </TermsOfUseMessage>
          )}

          {acceptedTerms && (
            <WelcomeMessage>
              <strong>Olá, você está na Onyma.</strong>

              <p>
                Nossos médicos precisam saber qual a urgência do seu caso. Por
                favor, responda o questionário abaixo rapidamente.
              </p>
            </WelcomeMessage>
          )}
        </Header>

        <Form>
          <ul>
            {acceptedTerms &&
              formConfig.map((question) => {
                if (question.type === "cpf") {
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <input
                          name={question.idQuestion}
                          required
                          type="cpf"
                          readOnly={cpfSubmit}
                          value={cpfUser}
                          placeholder="Digite seu CPF"
                          onChange={(e) => {
                            setCpfUser(cpfMask(e.target.value));
                            handleChange(e);
                          }}
                        />
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                      {!cpfSubmit && (
                        <button
                          type="submit"
                          onClick={(e) =>
                            handleCPFSubmit(e, question.idQuestion)
                          }
                        >
                          {loading ? "Validando..." : "Continuar"}
                        </button>
                      )}
                    </>
                  );
                }
                return null;
              })}

            {cpfSubmit &&
              acceptedTerms &&
              formConfig.map((question) => {
                if (question.type === "text") {
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <input
                          name={question.idQuestion}
                          required
                          type={question.type}
                          placeholder="Sua Resposta"
                          onChange={handleChange}
                        />
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                } else if (question.type === "name" && !appRegistered) {
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <input
                          name={question.idQuestion}
                          required
                          type="text"
                          value={userName}
                          placeholder="Seu nome completo"
                          onChange={(e) => {
                            handleChange(e);
                            setUserName(e.target.value);
                          }}
                        />
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                } else if (question.type === "tel" && !appRegistered) {
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <input
                          name={question.idQuestion}
                          required
                          type="tel"
                          value={telUser}
                          placeholder="(99) 99999-9999"
                          onChange={(e) => {
                            setTelUser(telefoneMask(e.target.value));
                            handleChange(e);
                          }}
                        />
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                } else if (question.type === "email" && missingUserEmail) {
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <input
                          name={question.idQuestion}
                          required
                          type="text"
                          value={userEmail}
                          placeholder="Digite seu email"
                          onChange={(e) => {
                            setUserEmail(e.target.value);
                            handleChange(e);
                          }}
                        />
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                } else if (question.type === "company" && missingUserCompany) {
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError='optional'
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        <span className="subinfo">Caso não tenha, deixe em branco.</span>

                        <input
                          name={question.idQuestion}
                          required
                          type="text"
                          value={userCompany}
                          placeholder="Digite o nome da empresa"
                          onChange={(e) => {
                            setUserCompany(e.target.value);
                            handleChange(e);
                          }}
                        />
                      </QuestionCard>
                    </>
                  );
                } else if (question.type === "date" && !appRegisteredBirth) {
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <TextField
                          name={question.idQuestion}
                          className="date"
                          label="Data"
                          type={question.type}
                          value={formResponses[question.idQuestion]}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleChange}
                          required
                        />
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                } else if (question.type === "radio-button") {
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <FormControl component="fieldset" required>
                          <RadioGroup
                            name={question.idQuestion}
                            value={formResponses[question.idQuestion]}
                            onChange={handleChange}
                          >
                            {question.possibleAnswers.map((answer) => (
                              <FormControlLabel
                                key={answer}
                                value={answer}
                                control={<Radio />}
                                label={answer === "Outro" ? "Sim" : answer}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                } else if (question.type === "radio-button-other") {
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <FormControl component="fieldset" required>
                          <RadioGroup
                            name={question.idQuestion}
                            value={formResponses[question.idQuestion]}
                            onChange={handleChange}
                          >
                            {question.possibleAnswers.map((answer) => (
                              <FormControlLabel
                                key={answer}
                                value={answer}
                                control={<Radio />}
                                label={answer === "Outro" ? "Sim" : answer}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                        {checkAnswer(question.idQuestion) && (
                          <input
                            name={question.idQuestion}
                            required
                            type="text"
                            placeholder="Qual?"
                            onChange={handleChange}
                          />
                        )}
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                } else if (question.type === "checkbox") {
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <FormGroup>
                          {question.possibleAnswers.map((answer) => (
                            <FormControlLabel
                              key={answer}
                              control={
                                <Checkbox
                                  onChange={handleChange}
                                  name={question.idQuestion}
                                  value={answer}
                                />
                              }
                              label={answer}
                            />
                          ))}
                        </FormGroup>
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                } else if (question.type === "number") {
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <input
                          name={question.idQuestion}
                          required
                          type={question.type}
                          placeholder="Sua Resposta"
                          onChange={handleChange}
                        />
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                }

                return true;
              })}
          </ul>
          {cpfSubmit && (
            <button type="submit" onClick={handleSubmit}>
              {loading ? "Enviando..." : "Enviar"}
            </button>
          )}
        </Form>
      </MainContainer>
    </>
  );
};

export default FormCovid;
